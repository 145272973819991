<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model.trim="cobradorObject.venNombre"
                label="Nombre o denominación"
                dense
                outlined
                :rules="
                  rules.required.concat([
                    rules.maxLength(cobradorObject.venNombre, 30),
                    rules.requiredTrim(cobradorObject.venNombre)
                  ])
                "
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pb-0">
              <v-autocomplete
                v-model="tipoIvaSelected"
                label="IVA"
                item-text="value"
                item-value="id"
                :items="tiposIva"
                :rules="ruleIVA(tipoIvaSelected)"
                outlined
                clearable
                dense
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="6" class="pb-0">
              <v-text-field
                v-model="cobradorObject.cuit"
                label="CUIT"
                type="text"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                dense
                outlined
                :rules="validarCUIL(cobradorObject.cuit)"
                v-mask="'##-########-#'"
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="cobradorObject.direccion"
                label="Dirección"
                dense
                outlined
                :rules="[rules.maxLength(cobradorObject.direccion, 50)]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="pb-0">
              <v-text-field
                v-model="cobradorObject.email"
                type="text"
                outlined
                dense
                :rules="
                  rules.email.concat([
                    rules.maxLength(cobradorObject.email, 100)
                  ])
                "
                label="Email"
                autocomplete="off"
              ></v-text-field>
            </v-col>

            <v-col cols="6" class="pb-0">
              <v-text-field
                v-model="cobradorObject.telefono"
                label="Teléfono"
                type="text"
                dense
                outlined
                :rules="[rules.maxLength(cobradorObject.telefono, 15)]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model="cobradorObject.observaciones"
                label="Observaciones"
                dense
                outlined
                auto-grow
                rows="2"
                :rules="[rules.maxLength(cobradorObject.observaciones, 300)]"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import validateCUIL from "@/utils/helpers/validateCUIL";
import { mask } from "vue-the-mask";

export default {
  name: "EditCobradores",
  directives: { mask },
  props: {
    cobrador: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    isFormValid: false,
    tipoIvaSelected: null,
    tiposIva: [],
    formEditTitle: enums.titles.EDITAR_COBRADOR,
    newTitle: enums.titles.NUEVO_COBRADOR,
    rules: rules,
    cuit: null,
    cobradorObject: {},
    observaciones: null
  }),
  created() {
    if (this.cobrador) {
      this.setCobrador(this.cobrador);
    } else {
      this.newCobrador();
    }
    this.setIva();
  },
  methods: {
    ...mapActions({
      getTiposIvaCobradores: "configuracion/getTiposIvaCobradores",
      postCobradores: "configuracion/postCobradores",
      setAlert: "user/setAlert"
    }),
    ruleIVA(ivaId) {
      if (ivaId == 0) return [];
      return rules.required;
    },
    validarCUIL(cuil) {
      if (cuil == null || cuil == "") return [];
      const res = validateCUIL.validateCUITCUIL(cuil);
      if (res === false) {
        return ["El CUIT ingresado es incorrecto."];
      }
    },

    async setIva() {
      const response = await this.getTiposIvaCobradores();
      this.tiposIva = response;
    },
    async setCobrador() {
      this.cobradorObject = this.cobrador;
      this.tipoIvaSelected = this.cobrador.tipoIva.id;
    },
    async newCobrador() {
      this.formEditTitle = this.newTitle;
    },
    async saveEdit() {
      const data = {
        venId: this.cobradorObject.venId,
        venNombre: this.cobradorObject.venNombre,
        ivaId: this.tipoIvaSelected,
        cuit: this.cobradorObject.cuit,
        telefono: this.cobradorObject.telefono,
        direccion: this.cobradorObject.direccion,
        email: this.cobradorObject.email,
        observaciones: this.cobradorObject.observaciones
      };
      const res = await this.postCobradores(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModal();
      }
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style></style>
